import React from "react"

const Footer = () => {

  const navs = (<ul className="nav-links-footer">
    <li className="nav-link-item">
      <a href="/">HOME</a>
    </li>
    {/* <li className="nav-link-item">
      <a href="/get-started">GET STARTED</a>
    </li>
    <li className="nav-link-item">
      <a href="/features"> FEATURES</a>
    </li> */}
    <li className="nav-link-item">
      <a href="/privacy-policy">PRIVACY POLICY</a>
    </li>
  </ul>)

  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-content">
          <div className="footer-line"></div>
          <nav className="navbar-footer">
            <div className="nav-belt-footer ">
              {navs}
            </div>
          </nav>
          <div className="social-media">
            <a href="https://www.linkedin.com">
              <div className=" social-linkedin"></div>
            </a>
            <a href="https://www.facebook.com">
              <div className="social-facebook"> </div>
            </a>
            <a href="https://www.twitter.com">
              <div className="social-twitter"> </div>
            </a>
            <a href="https://www.instagram.com">
              <div className="social-instagram"> </div>
            </a>
            <a href="https://www.google.com">
              <div className=" social-gplus"></div>
            </a>
            <a href="https://www.youtube.com">
              <div className=" social-youtube"></div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
