import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import LogoImage from "../../static/Images/logo.png"
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import { AnchorLink } from "gatsby-plugin-anchor-links";
 

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [changeNav, setChangeNav] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if(window.pageYOffset >= 50 && !changeNav) {
        setChangeNav(true);
      } else if(window.pageYOffset < 50 && changeNav) {
        setChangeNav(false);
      }
    });
  }, [changeNav]);

  const openDrawer = () => {
    setShowDrawer(true)
  }

  const closeDrawer=()=>{
    setShowDrawer(false)
  }

  const list = (
    <List>
      <Link className="logo-for-drawer" to="/">
        <div className="logo-image-for-header-div">
          {<img className="logo-image-for-drawer" alt="logo" src={LogoImage} />}
        </div>
      </Link>
      <ListItem className="kyu-list-item">
        <ListItemIcon>
          <HomeIcon />{" "}
        </ListItemIcon>
        <a href="/">HOME</a>
      </ListItem>
      <ListItem className="kyu-list-item">
        <ListItemIcon>
          {" "}
          <LibraryBooksIcon />
        </ListItemIcon>
        <a href="/privacy-policy"> PRIVACY POLICY </a>
      </ListItem>
      <ListItem className="kyu-list-item" onClick={closeDrawer}>
        <ListItemIcon>
          {""}
          <PermContactCalendarIcon />
        </ListItemIcon>
        <AnchorLink to="/#kyu-form">CONTACT US</AnchorLink>
      </ListItem>
    </List>
  )

  return (
    <header className="header">
      <nav className={`navbar ${changeNav ?  'navbar-opaque' : ''}`}>
        <div className="nav-belt">
          <div className="nav-belt-inner">
            <span className="nav-burger">
              <MenuIcon className="kyu-menu-burger" onClick={openDrawer} /> 
            </span>
            <Link className="logo" to="/">
              <div>
                {<img className="logo-image" alt="logo" src={LogoImage} />}
              </div>
            </Link>
          </div>
          <Drawer
            anchor={"left"}
            open={showDrawer}
            onClose={closeDrawer}
            className="kyu-drawer"
          >
            {list}
          </Drawer>
          <ul className="nav-links">
            <li className="nav-link-item">
              <a href="/">HOME</a>
            </li>
            <li className="nav-link-item">
              <a href="/privacy-policy"> PRIVACY POLICY </a>
            </li>
            <li>
              <AnchorLink to="/#kyu-form">CONTACT US</AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
