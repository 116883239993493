import React from "react"
import PropTypes from "prop-types"
import Header from "./Header"
import Footer from "./Footer"
import "../styles/index.scss"
import { Helmet } from "react-helmet"

const Layout = ({isHomePage, children }) => {
  const containerClass = isHomePage ? "container" : "container container-inner";
  return (
    <>
      <Helmet>
        <title>Kyuu</title>
      </Helmet>
      <Header siteTitle="Kyu Landing" />
      <div className={containerClass}>
        <main className="container-main">{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
